import { gql } from "@apollo/client";

const GET_AUTH_USER = gql`
  query GetAuthUser($id: Int!) {
    usersByPk: users_by_pk(id: $id) {
      id
      email
    }
  }
`;

export default GET_AUTH_USER;
