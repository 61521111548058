export const ORDER_BY_GRAPHQL_ENUM = {
  ASC: "asc",
  ASC_NULLS_FIRST: "asc_nulls_first",
  ASC_NULLS_LAST: "asc_nulls_last",
  DESC: "desc",
  DESC_NULLS_FIRST: "desc_nulls_first",
  DESC_NULLS_LAST: "desc_nulls_last",
};

export const FILE_TYPE = {
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  DOCUMENT: "DOCUMENT",
};

export const UPLOAD_TYPE = {
  IMAGE: "image",
  VIDEO: "video",
  DOCUMENT: "document",
};

export const IMAGE_TYPE_URL = {
  BANNER: "banner",
  POST: "post",
  USER: "user",
  SETTING: "setting",
  BLOG: "BLOG"
};

export const IMAGE_TYPE = {
  BLOG: "BLOG",
  KOL: "KOL",
  PROJECT: "PROJECT",
  PROJECT_GALLERY: "PROJECT_GALLERY",
  MEMBER: "MEMBER",
  HIRING: "HIRING",
};

export const IMAGE_SUBTYPE = {
  BLOG_THUMBNAIL: "BLOG_THUMBNAIL",
  KOL_AVATAR: "KOL_AVATAR",
  MEMBER_AVATAR: "MEMBER_AVATAR",
  HIRING_IMAGE: "HIRING_IMAGE",
  BLOG_IMAGE_CONTENT: "BLOG_IMAGE_CONTENT",
  PROJECT_THUMBNAIL: "PROJECT_THUMBNAIL",
  PROJECT_BANNER: "PROJECT_BANNER",
  PROJECT_OVERVIEW: "PROJECT_OVERVIEW",
  PROJECT_SECOND_CONTENT: "PROJECT_SECOND_CONTENT",
  PROJECT_GALLERY_SRC: "PROJECT_GALLERY_SRC",
};

export const ERROR_CONSTRAINT_VIOLATION_CODE = "constraint-violation";

export const LAYOUT_PAGE_ENUM = {
  NORMAL: "NORMAL",
  BEAUTIFUL_HOUSES: "BEAUTIFUL_HOUSES",
};
