import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import PropTypes from "prop-types";
import { setAuthToken } from "./jwt-api";
import { message } from "antd";
import useGetAuthUser from "@/hooks/apis/users/useGetAuthUser";
import { ApolloClientContext } from "@tinhaynhadat/services/auth/jwt-auth/ApolloClientContext";
import { createHttpLink, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import useLogin from "@/hooks/apis/auth/useLogin";
import {parseJwt} from "@tinhaynhadat/utility/Utils";

const JWTAuthContext = createContext(null);
const JWTAuthActionsContext = createContext(null);

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const createAuthApolloLink = (token) => {
  return createHttpLink({
    uri: process.env.REACT_APP_API_URL_GRAPHQL,
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
};
const createUnAuthApolloLink = () => {
  return createHttpLink({
    uri: process.env.REACT_APP_API_URL_GRAPHQL,
  });
};
const JWTAuthAuthProvider = ({ children }) => {
  const [firebaseData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });
  const [apolloClient] = useContext(ApolloClientContext);
  const [getUser] = useGetAuthUser();
  const { login } = useLogin();
  const initClients = useCallback(async () => {
    try {
      const token = localStorage.getItem("cms.token");
      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      
      const payload = parseJwt(token);
      const claims = payload ? JSON.parse(payload?.["https://hasura.io/jwt/claims"]) : null
      const userId = claims ? Number(claims?.["x-hasura-user-id"]) : null;
      const apolloLink = createAuthApolloLink(token);
      const unAuthLink = createUnAuthApolloLink()
      const errorLink = onError(({ graphQLErrors, operation, forward }) => {
        if (graphQLErrors) {
          for (let err of graphQLErrors) {
            switch (err.extensions.code) {
              case "invalid-jwt":
                setJWTAuthData({
                  user: undefined,
                  isLoading: false,
                  isAuthenticated: false,
                });
                apolloClient.setLink(from([unAuthLink]));
                localStorage.removeItem("token");
                message.error("Phiên đăng nhập hết hạn!");
                return forward(operation);
            }
          }
        }
      });
      apolloClient.setLink(from([errorLink, apolloLink]));
      const { data: { usersByPk: user } = {} } = await getUser({
        variables: {
          id: userId,
        },
      });
      if (user) {
        setJWTAuthData({
          user,
          isLoading: false,
          isAuthenticated: true,
        });
      } else {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        message.error("Tên đăng nhập hoặc mật khẩu không đúng.");
      }
    } catch (error) {
      console.log({ error });
      setJWTAuthData({
        user: undefined,
        isLoading: false,
        isAuthenticated: false,
      });
    }
  }, [getUser, apolloClient]);

  useEffect(() => {
    void initClients();
  }, [getUser, initClients]);

  const signInUser = async ({ username, password }) => {
    try {
      const loginRespond = await login({
        variables: {
          email: username,
          password
        },
      })
      const token = loginRespond?.data?.login?.token;
      localStorage.setItem("cms.token", token);
      await initClients();
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      message.error("Incorrect username or password.");
    }
  };

  const logout = async () => {
    try {
      localStorage.removeItem("token");
      setAuthToken();
      setJWTAuthData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const changePassword = async ({ oldPassword, newPassword }) => {
    // try {
    //   const user = await Auth.currentAuthenticatedUser();
    //   await Auth.changePassword(user, oldPassword, newPassword);
    // } catch (err) {
    //   console.log(err);
    //   throw err;
    // }
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signInUser,
          logout,
          changePassword,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
