import {MdOutlineNoteAlt, MdPostAdd} from "react-icons/md";
import {FaUserCheck} from "react-icons/fa";
import {RiShakeHandsFill, RiTeamLine} from "react-icons/ri";
import {TbCategory2} from "react-icons/tb";
import {IoPersonAddOutline} from "react-icons/io5";
import {FiUserPlus} from "react-icons/fi";

const routesConfig = [
  // {
  //   id: "dashboards",
  //   messageId: "sidebar.dashboards",
  //   type: "group",
  //   children: [
  //     {
  //       id: "dashboards",
  //       title: "Dashboards",
  //       messageId: "sidebar.dashboards",
  //       icon: <RiDashboardLine />,
  //       path: "/dashboards",
  //     },
  //   ],
  // },
  {
    id: "cms",
    title: "CMS",
    messageId: "sidebar.cms",
    type: "group",
    children: [
      {
        id: "blogs",
        title: "Blogs",
        messageId: "sidebar.cms.posts",
        icon: <MdPostAdd />,
        path: "/cms/blogs",
      },
      {
        id: "kols",
        title: "Kols",
        messageId: "sidebar.cms.kols",
        icon: <FaUserCheck />,
        path: "/cms/kols",
      },
      {
        id: "kolRegisters",
        title: "Kol Registers",
        messageId: "sidebar.cms.kolRegisters",
        icon: <FiUserPlus />,
        path: "/cms/kol-registers",
      },
      {
        id: "partners",
        title: "Partners",
        messageId: "sidebar.cms.partners",
        icon: <RiShakeHandsFill />,
        path: "/cms/partners",
      },
      {
        id: "memberCategories",
        title: "Member Categories",
        messageId: "sidebar.cms.memberCategories",
        icon: <TbCategory2 />,
        path: "/cms/member-categories",
      },
      {
        id: "members",
        title: "Members",
        messageId: "sidebar.cms.members",
        icon: <RiTeamLine />,
        path: "/cms/members",
      },
      {
        id: "hirings",
        title: "Hirings",
        messageId: "sidebar.cms.hirings",
        icon: <IoPersonAddOutline />,
        path: "/cms/hirings",
      },
      {
        id: "projects",
        title: "Projects",
        messageId: "sidebar.cms.projects",
        icon: <MdOutlineNoteAlt />,
        path: "/cms/projects",
      },
    ],
  },
];
export default routesConfig;
