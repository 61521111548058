import React from "react";

const Blogs = React.lazy(() => import("./Blogs"));
const Kols = React.lazy(() => import("./Kols"));
const KolRegisters = React.lazy(() => import("./KolRegisters"));
const Partners = React.lazy(() => import("./Partners"));
const MemberCategories = React.lazy(() => import("./MemberCategories"));
const Members = React.lazy(() => import("./Members"));
const Hirings = React.lazy(() => import("./Hirings"));
const Projects = React.lazy(() => import("./Projects"));

export const cmsConfig = [
  {
    path: "/cms/blogs",
    element: <Blogs />,
  },
  {
    path: "/cms/kols",
    element: <Kols />,
  },
  {
    path: "/cms/kol-registers",
    element: <KolRegisters />,
  },
  {
    path: "/cms/partners",
    element: <Partners />,
  },
  {
    path: "/cms/members",
    element: <Members />,
  },
  {
    path: "/cms/member-categories",
    element: <MemberCategories />,
  },
  {
    path: "/cms/hirings",
    element: <Hirings />,
  },
  {
    path: "/cms/projects",
    element: <Projects />,
  },
];
